import { get } from 'lodash';
import {
  DEFAULT_POST_LIST_SIZE,
  DEFAULT_CATEGORY,
  DEFAULT_FEATURED_POSTS,
  DEFAULT_TITLE_CHAR_COUNT,
  propertyKeys,
} from '../constants/post-list-widget';
import { DESKTOP } from '../constants/widget-device-types';
import { getMainPanelProperties } from './config-selectors';

export const getPostListSize = (config, deviceType = DESKTOP) =>
  get(getMainPanelProperties(config, deviceType), [propertyKeys.POST_LIST_SIZE], DEFAULT_POST_LIST_SIZE);

export const getTitleCharCount = (config, deviceType = DESKTOP) =>
  get(getMainPanelProperties(config, deviceType), [propertyKeys.TITLE_CHAR_COUNT], DEFAULT_TITLE_CHAR_COUNT);

export const getFeaturedPostsEnabled = (config, deviceType = DESKTOP) =>
  get(getMainPanelProperties(config, deviceType), [propertyKeys.FEATURED_POSTS], DEFAULT_FEATURED_POSTS);

export const getCategory = (config, deviceType = DESKTOP) =>
  get(getMainPanelProperties(config, deviceType), [propertyKeys.CATEGORY], DEFAULT_CATEGORY);
