const STATE_BOX = '@stateBox';
const MEDIA_BOX = '@mediaBox';
const POST_LIST = '@postList';
const POST_IMAGE = '@postImage';
const POST_TITLE = '@postTitle';
const POST_DATE = '@date';

export const itemSelectors = {
  STATE_BOX,
  MEDIA_BOX,
  POST_DATE,
  POST_LIST,
  POST_IMAGE,
  POST_TITLE,
};

const FEATURED_POSTS = 'featuredPosts';
const POST_LIST_SIZE = 'postListSize';
const CATEGORY = 'category';
const TITLE_CHAR_COUNT = 'titleCharCount';

export const propertyKeys = {
  FEATURED_POSTS,
  POST_LIST_SIZE,
  CATEGORY,
  TITLE_CHAR_COUNT,
};

export const DEFAULT_POST_LIST_SIZE = 3;
export const DEFAULT_TITLE_CHAR_COUNT = 48;
export const DEFAULT_FEATURED_POSTS = false;
export const DEFAULT_CATEGORY = null;
export const DEFAULT_VERTICAL_SPACING = 1;

export const POST_LIST_SIZE_MIN = 1;
export const POST_LIST_SIZE_MAX = 30;
