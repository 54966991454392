import { isEmpty } from 'lodash';
import postService from '../services/post';
import helpers from '../services/helpers';

import { itemSelectors } from '../constants/post-list-widget';
import { EMPTY_STATE } from '../constants/state-box-states';
import {
  getPostListSize,
  getCategory,
  getFeaturedPostsEnabled,
  getTitleCharCount,
} from '../selectors/post-list-widget-selectors';

const postListWidgetController = ({ $w, appParams, wixCodeApi, config }) => ({
  pageReady: () => {
    $w(itemSelectors.POST_LIST).onItemReady(($item, itemData) => {
      $item(itemSelectors.POST_IMAGE).src = itemData.coverImage;
      $item(itemSelectors.POST_TITLE).text = helpers.applyCharLimitEllipsis(itemData.title, getTitleCharCount(config));
      $item(itemSelectors.POST_TITLE).onClick(() =>
        wixCodeApi.location.to(`${wixCodeApi.location.baseUrl}${itemData.postPageUrl}`),
      );
      $item(itemSelectors.POST_DATE).text = helpers.getLastPublishedDate(itemData.lastPublishedDate);
    });

    const category = getCategory(config);

    const params = {
      limit: getPostListSize(config),
      featured: getFeaturedPostsEnabled(config),
      ...(category && { category }),
    };

    postService
      .getPosts({ instance: appParams.instance, params, wixCodeApi })
      .then(posts => {
        if (isEmpty(posts)) {
          $w(itemSelectors.STATE_BOX).changeState(EMPTY_STATE);
        } else {
          $w(itemSelectors.POST_LIST).data = posts;
        }
      })
      .then(() => $w(itemSelectors.MEDIA_BOX).show())
      .catch(console.error);
  },
});

export default postListWidgetController;
