import { truncate } from 'lodash';

export const applyCharLimitEllipsis = (str, limit) => truncate(str, { length: limit, separator: '...' });

export const getLastPublishedDate = lastPublishedDate =>
  new Date(lastPublishedDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

export default {
  getLastPublishedDate,
  applyCharLimitEllipsis,
};
